<template>
  <span v-if="orderItem">
    <div class="list__item list__item--orderitem">
      <div class="list__item__image">
        <img
          :src="orderItem.image ? orderItem.image : `https://images.cyclingfactory.be/${orderItem.externalid}_image_400x.jpg`"
          :alt="orderItem.externalid"
          @error="imgFailed"
          @click="openLightboxImage(orderItem, $event)"
        >
      </div>
      <div class="list__item__info">
        <div class="list__item__info__section align-items-end m-0 p-0 mb-2 mh-3 top-section">
          <div class="d-flex align-items-center date-and-labels">
            <small v-if="orderItem.shipped !== true && orderItem.promiseddeliverydate">ETD {{ formatdate(orderItem.promiseddeliverydate) }}</small>
            <small v-if="orderItem.shipped === true"><i class="uil uil-truck" /> {{ formatdate(orderItem.shipmentdate) }}</small>
            <div class="d-flex align-items-center labels">
              <!-- Badge preorder -->
              <Badge
                v-if="orderItem.order.preorder"
                type="outlet"
              >{{ $t('account.account.myOrders.preorderLabel') }}</Badge>
              <!-- Badge Shipped -->
              <Badge
                v-if="orderItem.shipped"
                type="shipped"
              >{{ $t('account.account.myOrders.doneStatus.shipped') }}</Badge>
            </div>
          </div>
          <span
            v-if="orderItem.serial"
            class="tag tag__serial"
          >
            <i
              class="uil uil-pricetag-alt"
            /> {{ orderItem.serial }}
          </span>
        </div>
        <div class="list__item__info__section">
          <header>
            <!-- Title -->
            <strong>{{ orderItem.description }}</strong>
          </header>
        </div>
        <div class="list__item__info__section">
          <div class="list__item__info__section--left justify-content-between align-items-start">
            <div>
              <span
                v-if="orderItem.nextstatus"
                class="list__item__orderitem__status"
              >{{ $t('account.account.myOrders.pendingStatus.' + orderItem.nextstatus) }}</span>
              <span class="list__item__orderitem__articlenumber">- {{ orderItem.externalid }}</span>
            </div>
            <div
              class="list__item__orderitem__actions"
              :class="{ 'unreferenced': !orderItem.references}"
            >
              <div
                v-if="orderItem.change_pending"
                class="change_pending"
              >
                Processing design/size change...
              </div>
              <template v-else>
                <div
                  v-if="canUpdateReference"
                  class="button button--small button--icon"
                  @click="editOrderItemReference(orderItem)"
                >
                  <i
                    class="uil"
                    :class="{ 'uil-user-plus': !orderItem.reference, 'uil-user-check': orderItem.reference }"
                  />
                  <span v-if="orderItem.reference">{{ orderItem.reference.firstName }} {{ orderItem.reference.lastName }}</span>
                </div>
                <router-link
                  v-if="canUpdateDesign"
                  :to="{name: 'editPreorder', params: {orderId: orderItem.order.externalorderid || orderItem.order.id.toString(), orderlineId: orderItem.orderline, orderlineBikeIndex: orderItem.orderline_bike_index}}"
                  style="text-decoration: none;"
                  class="button button--small button--icon"
                  alt="Change design/size"
                >
                  <i
                    class="uil uil-paint-tool"
                  />
                </router-link>
              </template>
            <!-- <span v-if="!orderItem.reference">Toewijzen</span> -->
            </div>
          </div>
          <router-link
            :to="{name: 'myBikeOrdersView', params: {id: orderItem.order.externalorderid || orderItem.order.id.toString()}}"
            style="text-decoration: none;"
            class="list__item__info__section--right justify-content-end align-items-end w-auto"
          >
            <span class="list__item__orderitem__ordernumber">{{ orderItem.order.externalorderid || "-" }}</span>
            <time
              v-if="orderItem.order.orderdate"
              class="list__item__orderitem__date"
            >
              {{ formatdate(orderItem.order.orderdate) }}
            </time>
            <small
              v-if="orderItem.order_reference"
              class="list__item__orderitem__reference"
            >{{ orderItem.order_reference }}</small>
          </router-link>
        </div>
      </div>
    </div>
    <LightBox
      ref="lightbox"
      :images="lightboxImage"
      :show-caption="false"
      :show-light-box="false"
      :show-thumbs="false"
      background-color="white"
    />
  </span>
</template>

<script>
import { formatDate } from '@/utils/dateUtils';
import dialogs from '@/utils/dialogs';
import OrderlineReference from '@/views/account/account/myorders/orderlineReference.vue';
import LightBox from '@/components/LightBox/LightBox.vue';
import Badge from '@/elements/Badge.vue';

export default {
  name: 'ListItemOrderItem',
  components: {
    LightBox,
    Badge,
  },
  inheritAttrs: true,
  props: {
    selected: Boolean,
    orderItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lightboxImage: [],
    };
  },
  computed: {
    canUpdateReference() {
      return false;
    },
    canUpdateDesign() {
      return false;
      // return this.orderItem.order.preorder;
    },
  },
  methods: {
    imgFailed(event) {
      event.target.failed = true; // this prevents the lightbox from opening when clicked, see openLightboxImage method.
      // eslint-disable-next-line global-require
      event.target.src = require('@/assets/img/product_placeholder.jpg');
    },
    formatdate(dte) {
      return formatDate(dte);
    },
    async editOrderItemReference() {
      const { order } = this.orderItem;
      const result = await dialogs.show({
        component: OrderlineReference,
        props: {
          singleItem: true,
          orderline: this.orderItem,
          order,
          value: this.orderItem.references,
        },
      });
      if (result) {
        this.$emit('updated');
      }
    },
    async editDesign() {

    },
    openLightboxImage(item, event) {
      if (!event.target.failed) {
        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();

        const image = {
          thumb: item.image ? item.image : `https://images.cyclingfactory.be/${item.externalid}_image_400x.jpg`,
          src: item.image ? item.image : `https://images.cyclingfactory.be/${item.externalid}_image_2048x.jpg`,
          caption: item.description,
        };
        this.lightboxImage = [{
          ...image,
          key: 'product',
        }];
        this.$refs.lightbox.showImage(0);
      }
    },
  },
};
</script>

<style scoped>

  .top-section {
    min-height: 3rem;
  }

  .date-and-labels small {
    font-size: 1.7rem;
    margin-top: 0;
    margin-right: 1rem;
  }

  .tag__serial{
    width: auto;
    max-width: 80rem;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height:1.5rem;
    font-size:1.4rem;
    font-family: monospace;
    font-weight: 900;
    letter-spacing: 0.1rem;
    background: black;
    padding: 0.6rem 0.55rem 0.3rem 0.3rem;

    margin: 0;

    color: #CCC;
  }

  .tag__serial i {
    margin-top: 0rem;
    font-size: 1.8rem;
  }
</style>
